import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { PeopleGroupAuthorization, peopleGroupAuthorizationAdapter, Permission } from './people-group-authorization.state';
import { selectApp } from './core.selectors';
import { AppState } from './core.state';

const { selectEntities, selectAll } =
  peopleGroupAuthorizationAdapter.getSelectors();

export const selectPeopleGroupAuthorization = createSelector(
  selectApp,
  (state: AppState) => state.peopleGroupAuthorization,
);

export const selectAllPeopleGroupAuthorizationEntries = createSelector(
  selectPeopleGroupAuthorization,
  selectAll,
);
export const selectPeopleGroupAuthorizationEntities = createSelector(
  selectPeopleGroupAuthorization,
  selectEntities,
);

export const selectPeopleGroupAuthorizationById = (objectId: string) =>
  createSelector(
    selectPeopleGroupAuthorizationEntities,
    (entities) => entities[objectId],
);

export const selectPermissionForObjectById = (objectId: string | null) =>  createSelector(
  selectPeopleGroupAuthorizationEntities,
  (entities: Dictionary<PeopleGroupAuthorization>): PeopleGroupAuthorization | null => {
    return getPeopleGroupAuthorization(objectId, entities);
  },
);

export const selectHasPermissionForObjectById = (objectId: string | null, permission: Permission) =>
  createSelector(
    selectPermissionForObjectById(objectId),
    (authorization) => {
      return hasObjectPermission(authorization, permission);
    },
);

const getPeopleGroupAuthorization = (
  objectId: string | null,
  entities: Dictionary<PeopleGroupAuthorization>) : PeopleGroupAuthorization | null => {
  if (objectId) {
    return entities[objectId] ?? null;
  } else {
    return null;
  }
}

const hasObjectPermission = (
  authorization: PeopleGroupAuthorization | null,
  permission: Permission) : boolean | null => {
  if (authorization === null){
    return null
  }
  return authorization.permissions.includes(permission)
}
