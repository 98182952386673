@if (appVersion$ | async; as appVersion) {
  <kendo-dialog class="dialog-mask">
    <div class="message" aogTestId="welcome-message" [attr.message-id]="appVersion">
      <div class="message-header">
        <h1>Welcome to AnyOrg version {{appVersion}}</h1>
      </div>
      <div class="message-body">
        <p>We are thrilled to introduce the latest version of AnyOrg, packed with exciting feature enhancements.</p>
        <p>Please access the release notes <a href="https://wiki.swisscom.com/x/aLf9Ow" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
        <p>We hope you enjoy using AnyOrg and find the new features valuable.</p>
        <p>Thank you for your continued support and, please feel free to send your feedback and suggestions to our service desk.</p>
        <div class="callout-block">
          <p>Info: </p>
          <ul>
            <li>By accessing AnyOrg, you are consenting to automatically accept the listed 
              <a href="https://wiki.swisscom.com/x/l5HUTw" target="_blank" rel="noopener noreferrer">
                <b>Terms & Conditions</b></a>.
              </li>
            <li>If you have a feature request or feedback, you can use the link <b>"Feedback & Support"</b> in the footer.</li>
          </ul>
        </div>
        <button class="close-button" (click)="onClose(appVersion)" kendoButton themeColor="primary" aogTestId="welcome-message-close">
          Close
        </button>
        </div>
    </div>
  </kendo-dialog>
}
